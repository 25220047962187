.TaxCalculator {
  height: auto;
  background-image: url("../../assets/Images/bg-advisory.jpg");
  background-repeat: no-repeat;
  /* background-attachment:; */
  background-size: cover;
  background-position: center;
  opacity: 0.7;
  padding-top: 25px;
  padding-bottom: 40px;
  color: aliceblue;
}
.TaxCalculator > h3 {
  color: aliceblue;
  padding-bottom: 10px;
}

.calculator {
  outline: 2px groove white;
  background-color: rgba(38, 138, 225, 0.674);
  height: auto;
  padding: 20px;
  width: 70%;
  border-radius: 5%;
  color: white;
  margin-left: 10%;
}
.box {
  outline: 1px solid white;
  height: 40px;
  width: 160px;
  padding: 3px;
  text-align: left;
  border-radius: 2%;
  margin-top: 10px;
  color: white;
  text-shadow: 1rem #1a8ae5;
}

.inputsec {
  padding-top: 35px;
}
.inputsec .select {
  color: #1a8ae5;
}

.input {
  outline: 1px solid white !important;
  height: 40px;
  width: 150px;
  color: #1a8ae5;
  padding: 3px;
  text-align: left;
  border-radius: 3%;
  /* margin-top: 10px; */
  background-color: aliceblue;
}
.calculator .btn {
  background-color: #1a8ae5;
  outline: #1a8ae5;
}
.calculator .btn:hover {
  background-color: #3bb9ff;
}

@media (min-width:0px) and (max-width: 767px) {
  .inputStyles {
    flex-direction: column;
  }
  .inputStylesRow {
    flex-direction: row;
  }
}
