#testimonials{   
    text-align:left;
    /* background-color: #64b7f6; */
    color:white;
    margin: 0%;
    height: 400px;
    width: 100%;
    background-image: url("../../assets/Images/web\ banner-09.jpg");
    background-size:cover;
    background-repeat: no-repeat;
    padding-top: 2%;
  }
  .intro{
    border-radius: 1%;
    width: 40%;
    font-size: 22px;
    color:aliceblue;
    padding: 5%;
    font-weight: 600;
    margin-top: 4%;
    margin-left: 5%;

  }

  @media (min-width:768px) and (max-width: 991px){
    #testimonials{   
      text-align:left;
      /* background-color: #64b7f6; */
      color:white;
      margin: 0%;
      height: 400px;
      width: 100%;
      background-image: url("../../assets/Images/web\ banner-06.jpg");
      background-size:cover;
      background-repeat: no-repeat;
      padding-top: 2%;
    }
    .intro{
      border-radius: 1%;
      width: 60%;
      font-size: 20px;
      color:aliceblue;
      padding: 3%;
      /* font-weight: 600; */
      margin-top: 4%;
      margin-left: 5%;
  
    }


  }

  
  @media only screen and (max-width: 768px) {
    
    #testimonials{   
        text-align:left;
        color:white;
        margin: 0%;
        height: 350px;
        width: 100%;
        background-image: url("../../assets/Images/web\ banner-04.jpg");
        background-size:cover;
        padding-top: 2%;
        background-position:center;
        overflow-wrap: break-word;
        overflow: hidden; 
      } 
    
    .intro{
      
        border-radius: 1%;
        width: 60%;
        font-size: 16px;
        color:aliceblue;
        padding: 5%;
        font-weight: 500;
        margin-top: 4%;
        margin-left: 5%;
    
      }

   }
