
.navigation{
  height: 70px;
  /* background-color: #64b7f6; */
}

.navimg {
    height: 75px;
    width: 75px;  
    margin-left:40px;
    margin-right: 0px;    
}

.textsec{
  padding-top: 10px;
  margin-left:150px;
  text-align: left;
  position:absolute;
}
.logotext{
  color: #1a8ae5;
  font-weight:bold;
  font-size: larger;
  text-shadow: 3pt cyan;
  text-transform: uppercase;
  font-family: 'Times New Roman', Times, serif;
}
.logop{
  color:#64b7f6;
  font-size:17px;
  text-align: center;
  font-kerning: 2pt;
  font-family: 'Times New Roman', Times, serif;
}

.navsocial{
  font-size: medium;
}

.navbar-toggler-icon { 
  background-color: #64b7f6;
  color: aliceblue;
}
.navbar-nav > ul {
  margin-top: 30%;
}

.navbar-nav > ul > li > a.active {
  color: #1a8ae5;
  text-align: right;
  font-size: 18px;
}

.navbar-nav > .active > a {
  color: #1a8ae5;
  font-size: 16px;
}
.navbar-nav > .active > a:hover {
  color: #64b7f6;
  font-size: 18;
  cursor: pointer;
  padding-left: 15px;
  transition: all 0.52s ease;
}

.navbar-toggler{
    border-color: none;
    margin-left: none;

}

@media (min-width:768px) and (max-width: 991px) {
  
  .navimg {
    height: 75px;
    width: 75px;
    margin-top:-15px;
    margin-left:20px;
  
}
.textsec{
    padding-top: 10px;
    margin-left: 120px;
    position:absolute;
}
.navbar-navsm{
  background-color:aliceblue;
  text-align: center;
  display: list-item;
  width: 150px;
}
.toggle {
  position: absolute;
  top: 10px;
  right: 10px;
}
.navleft{
  position:absolute;
  left:0;
  top:30;
  right:0;

 
}

}

@media (min-width:0px) and (max-width: 767px) {
  .navimg {
      height: 75px;
      width: 75px;
      margin-top:-14px;
      margin-left:-20px;
      position:relative;
    
  }
  .textsec{
      padding-top: 10px;
      margin-left:70px;
      /* visibility:hidden; */
      position: absolute;
  }
  .logotext{
    color: #1a8ae5;
    font-weight:bold;
    font-size: medium;
    text-shadow: 3pt cyan;
    text-transform: uppercase;
    font-family: 'Times New Roman', Times, serif;
  }
  .navigation{
    height: 74px;
  }
  .navbar-navsm{
    background-color:aliceblue;
    text-align: center;
    display: list-item;
    width: 150px;
  }
  .navbar-toggler-icon { 
    background-color: #64b7f6;
    color: aliceblue;
  }
  .toggle {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .logop{
    color:#64b7f6;
    font-size:15px;
    text-align: center;
    font-kerning: 2pt;
    font-family: 'Times New Roman', Times, serif;
  }
  .navleft{
    position:absolute;
    left:0;
    top:30;
    right:0;

   
  }
  
 }
