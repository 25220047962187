#footer {
  background: #0747a1af;
  padding: 0 0 15px 0;
  color: #fff;
  font-size: 16px;
  background-image: url("../../assets/Images/logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left;
  background-size: cover;
  opacity: 0.9;
}

#footer .footer-top {
  background: #0747a145;
  /* border-top: 1px solid #17455e;
    border-bottom: 1px solid #123649; */
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  color: #64b7f6;
  font-weight: 600;
}

#footer .footer-top .footer-info .infop {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #0747a1af;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #64b7f6;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 18px;
  font-weight: 600;
  color: aliceblue;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

 #footer .footer-top .footer-links ul .icon {
  /* padding-right: 2px;
  padding-top: 2px; */
  color: #64b7f6;
  font-size: 14px;
  /* line-height:;  */
}
  

#footer .footer-top .footer-links ul li {
  padding: 2px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  /* line-height: 1px; */
  text-decoration: none;
}

#footer .footer-top .footer-links ul a:hover {
  color: #64b7f6;
  font-size: 22;
  cursor: pointer;
  padding-left: 15px;
  transition: all 0.52s ease;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
  font-size: 14;
}

#footer .copyright {
  text-align: center;
  padding-top: 10px;
}


#footer .logotext{
  color:aliceblue;
  font-weight:bold;
  font-size: larger;
  text-shadow: 3pt cyan;
  text-transform: uppercase;
}
@media (min-width:0px) and (max-width: 768px){

  #footer {
    background: #0747a1af;
    padding: 0 0 15px 0;
    color: #fff;
    font-size: 16px;
    background-image: url("../../assets/Images/logo.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left;
    background-size: cover;
    opacity: 0.9;
    align-items: center;
    text-align: center;
    
  }
  .link{
    vertical-align: middle;
    align-items: center;
    margin-left: 8em;

  }
  .links{
  
    text-align:justify; 
    left: 0; 
    margin-left: 3rem;
    /* padding-left: 80em; */
  }
/*   
#footer .footer-links ul {
  list-style: none;
  position:relative;
  margin-left: 230em;
  align-items: center;
  text-align: center;
} */


#footer .footer-links ul .icon {
  /* padding-right: 2px;
  padding-top: 2px; */
  color: #64b7f6;
  font-size: 14px;
  /* line-height:;  */
}
  

#footer .footer-links ul li {
  padding: 2px 0;
  /* display: flex; */
  align-items: center;
}

#footer .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  /* display: inline-block; */
  /* line-height: 1px; */
  text-decoration: none;
  align-items: center;
  text-align: center;
}



}

/* #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  } */

/* #footer .credits a {
    color: #a2cce3;
  } */
