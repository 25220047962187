#contactbar {
  height: 60px;
  outline: 1px;
  background-color: #0747a1af;
  color: whitesmoke !important;
  padding-left: 7%;
  padding-top: 2%;
  padding-bottom: 1%;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  /* overflow-wrap: break-word; */
  overflow: hidden;
  
 
}
.Vcontact {
  border-left: 1px solid white;
  height: 40px;
  margin-left: 10px;
  margin-right: 19px;
  padding-bottom: 10px;
  margin-top: -16px;
}
/* .pright {
  font-family: "Roboto", sans-serif;
  color: whitesmoke;
  margin-left: 32%;
} */

@media (min-width:0px) and (max-width: 768px) {
  #contactbar {
    height: 50px;
    outline: 1px;
    background-color: #0747a1af;
    /* background-image: url("../../assets/Images/contactbg.png"); */
    color: whitesmoke !important;
    padding-top: 2%;
    padding-bottom: 1%;
    font-family: "Roboto", sans-serif !important;
    font-size: 9px;
    /* overflow-wrap: break-word; */
    overflow: hidden;
   
    
  }
  .Vcontact {
    border-left: 1px solid white;
    height: 35px;
    margin-left: 10px;
    margin-right: 19px;
    padding-bottom: 10px;
    margin-top: 0px;
  }
  .hide{
    display:none;
  }
  
}
