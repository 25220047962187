h1 {
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #1065c0;
  padding: 3%;
}

hr {
  width: 20%;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #1a8ae5;
}

.service {
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #0747a1;
  height: 580;
  border: #0747a1;
  padding: 1%;
  font-size: 16px;
  font-weight: 550;
  background-color: aliceblue;
  margin-top: 2%;
  border-radius: 2%;
  outline:1px solid #1065c0;
  box-shadow: 5px 10px 18px #888888;
  margin-left: 7px;

}
.service:hover{
  opacity: .7;
}

p {
  font-family: "Roboto", sans-serif;
  /* font-weight: 600; */
}


.service > img {
  height: 190px;
  width: 190px;
}

@media (min-width:0px) and (max-width: 767px){
 
  .service > img {
    height: 350px;
    width: 350px;
  }
}