
.team {
  background: #fff;
  padding-bottom: 30px;
  font-family: "Roboto", sans-serif;
}
.card{
  width: 100%;
  height:550px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}

.title{
  color: #1065c0;
  font-weight: 500;
  text-transform: uppercase;
}
.tspan {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
  color: #68A4C4;
}
.teaminfo {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color:gray;
}

